<template>
  <div class="login">
    <div v-if="!token" @click="goLogin" class="notLogin">登录</div>
    <div v-else class="center" @click="userInfoPanel=!userInfoPanel">
      <div v-if="userInfoPanel" class="line" />
      个人中心
      <svg-icon v-if="userInfoPanel"  class="down_arrow" size="12" icon-class="down_arrow" />
    </div>
    <div v-if="userInfoPanel" class="menu">
      <div class="item" v-for="(item,index) in menuList" :key="index" @click="item.method" :class="{'cur':curMenu(item)}">
        {{item.name}}
      </div>
    </div>
  </div>
</template>

<script>
import { removeToken } from "@/utils/auth";
export default {
  name: "Login",
  data() {
    return {
      userInfoPanel: false,
      menuList: [
        //{ name: "设置", method: this.settings, path:'settings' },
        { name: "订单", method: this.userOrder, path:'order'},
        //{ name: "消息", method: this.msg, path:'msg' },
        { name: "退出", method: this.logOut }
      ]
    };
  },
  props: {
    token: String
  },
  methods: {
    curMenu(item){
      if(this.$route.path == '/'+item.path) return true;
    },
    goLogin() {
      if (this.$route.path.indexOf("login") !== -1) return;
      this.$router.push({
        path: "/login"
      });
    },
    settings(){
      this.$router.push({
        path: "/settings"
      });
    },
    userOrder() {
      this.$router.push({
        path: "/order"
      });
      this.userInfoPanel = false;
    },
    msg(){
      this.$router.push({
        path: "/msg"
      });
    },
    logOut() {
      removeToken();
      // window.location.reload();
      this.$Message.success("退出成功!");
      this.$router.replace({ 
        path: "/" 
      });
      this.userInfoPanel = false;
    }
  }
};
</script>

<style lang="less" scoped>
  .login {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 88px;
    position: relative;
    .notLogin{
      cursor:pointer;
      letter-spacing: 5px;
    }
    .center {
      cursor:pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      color: @warn;
      font-size: 16px;
      position: relative;
      .line {
        position: absolute;
        top: 0;
        width: 100%;
        height: 3px;
        border-radius: 3px;
        background: @warn;
      }
      .down_arrow {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
      }
    }
    .menu {
      position: absolute;
      width: 100%;
      top: calc(100%);
      left: 0;
      background: #282931;
      //color: #808081;
      font-size: 16px;
      .item {
        height: 28px;
        margin: 0 10px;
        display: flex;
        cursor:pointer;
        justify-content: center;
        align-items: center;
        color:#ccc;
        //border-bottom: 1px dashed #808081 ;
        &:last-child {
          border: 0;
          //color:#ff7400
        }
      }
      .cur{
        color:#ff7400;
      }
    }
  }
</style>
