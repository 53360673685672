import Vue from "vue";
import VueRouter from "vue-router";
import { Message } from "view-design";
Vue.use(VueRouter);

/* Layout */
import Layout from "@/layout";

/* 各模块路由 */

import loginRouter from "./modules/login";
import gamesRouter from "./modules/game";

const routes = [
  {
    path: "/",
    name: "/",
    component: Layout,
    redirect: "Home",
    children: [
      {
        path: "Home",
        component: () => import("@/views/Home/index"),
        name: "Home",
        meta: { title: "首页" }
      },
      {
        path: "Subject",
        component: () => import("@/views/Subject/index"),
        name: "Subject",
        meta: { title: "专题" },
        children: []
      },
      {
        path: "Detail",
        component: () => import("@/views/Subject/Detail.vue"),
        name: "Detail",
        meta: { title: "详情" }
      },
      {
        path: "Discount",
        component: () => import("@/views/Discount/index"),
        name: "Discount",
        meta: { title: "折扣" }
      },
      {
        path: "Hot",
        component: () => import("@/views/Hot/index"),
        name: "Hot",
        meta: { title: "热门" }
      },
      {
        path: "order",
        component: () => import("@/views/order/index"),
        name: "order",
        meta: { title: "我的订单", logged: true }
      },
      {
        path: "settings",
        component: () => import("@/views/Settings/index"),
        name: "settings",
        meta: { title: "设置", logged: true }
      },
      {
        path: "msg",
        component: () => import("@/views/Msg/index"),
        name: "msg",
        meta: { title: "我的消息", logged: true }
      }
    ]
  },
  loginRouter,
  gamesRouter,
  {
    path: "/icon",
    name: "Icon",
    component: () => import("../components/icon.vue"),
    meta: { title: "icon图标" }
  }
];

const router = new VueRouter({
  routes,
  mode: "history"
});

import { getToken } from "@/utils/auth";

router.beforeEach((to, from, next) => {
  // 判断有没有token
  const token = getToken();
  if (token) {
    if (to.path === "/login") {
      // 如果已登录， 则重定向
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    // 访问需要登录状态的页面
    if (to.meta.logged) {
      Message.error("您还未登录，请先登录!");
      next({ path: `/login?redirect=${to.path}` });
      return;
    } else {
      next();
    }
  }
  // 页面标题
  window.document.title = to.meta.title || to.name;
  next();
});

//解决新开页面后滚动条的位置停留在上个页面位置的问题
router.afterEach(() => {
  window.scrollTo(0, 0);
  document.querySelector("body").setAttribute("style", "overflow: auto !important;");
});

export default router;
