<template>
  <div class="Navbar">
    <div class="main">
      <tab-bar :activeItem="activeItem" />
      <div class="feature">
        <search class="search" />
        <login :token="token" />
      </div>
    </div>
  </div>
</template>

<script>
import tabBar from "./tabBar.vue";
import Search from "./search.vue";
import Login from "./Login.vue";

export default {
  name: "Navbar",
  components: { tabBar, Login, Search },
  props: {
    token: String,
    activeItem: String
  }
};
</script>

<style lang="less" scoped>
  .Navbar {
    background: #0f1014;
    Opacity: .9;
    width: 100%;
    font-size: @font-size;
    .main {
      display: flex;
      justify-content: space-between;
      max-width: @main-width;
      height: 60px;
      margin: 0 auto;
      .feature {
        display: flex;
        color: #fff;
        .search {
          margin-right: 40px;
        }
      }
    }
  }
</style>
