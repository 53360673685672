<template>
  <div class="seach">
    <div class="input">
      <input type="text" placeholder="想找什么游戏?" v-model="keyword" @keydown.enter="search()">
      <div class="icon" @click="search()">
        <svg-icon icon-class="search" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Search",
  data() {
    return {
      "keyword": ""
    };
  },
  methods: {
    search() {
      if (!this.empty(this.keyword)) {
        if(this.$route.path.indexOf("detail") != -1 || this.$route.path.indexOf("order") != -1){
          //详情页和订单页搜索直接跳转到首页
          this.$router.replace({ path: "/Home", query: { keyword: this.keyword } });
        } else {
          //首页则直接查询
          const routerview = this.$parent.$parent.$refs.main.$refs.routerview; // 获取首页展示游戏数据的组件 TODO 使用事件总线 this.$bus
          routerview.page = 1;
          routerview.searchKey = this.keyword;
          routerview.filterGame();
        }
      }
    },
    empty(str) {
      const _str = str.trim();
      if (_str != "" && _str != undefined && _str != null) {
        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="less" scoped>
  .seach {
    display: flex;
    align-items: center;
    .input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 32px;
      width: 210px;
      border-radius: 32px;
      padding: 0 12px;
      background: #3F4043;
      input {
        width: 85%;
        height: 32px;
        background: transparent;
        color: #fafafa;
        //font-family: SourceHanSansCN-Normal;
        &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
          color: #c3c3c3;
          font-size: 14px;
        }
        &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          color: #c3c3c3;
          font-size: 20px;
          font-weight: bolder;
        }
        &::-moz-placeholder { /* Mozilla Firefox 19+ */
          color: #c3c3c3;
          font-size: 20px;
          font-weight: bolder;
        }
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: #c3c3c3;
          font-size: 20px;
          font-weight: bolder;
        }
      }
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
        width: 30px;
      }
    }
  }
</style>
