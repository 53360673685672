  const state = {
    isCar:false,
    gameList: []
  };

  const getters = {
    getGameList: state => state.gameList,
  };
  
  const mutations = {
    ADD_GAME(state, data) {
      state.gameList.push(data)
    },
    DEL_GAME(state, index) {
      state.gameList.splice(index,1)
    },
    EMPTY_GAME(state){
      //vue2 直接给数组赋值不能做到响应式
      state.gameList.splice(0)
    },
    //切换购物车状态 status=true 弹出 status=false 收回
    switchCarStatus(state,status){
      state.isCar = status
    }
  };
  
  const actions = {
    addGame({commit}, {game_info}) {
      commit("ADD_GAME", game_info);
    },
    // delGame({ commit }, data) {
    //   commit("DEL_GAME", data);
    // }
  };
  
  export default {
    namespaced: true, // namespaced: true 的方式使其成为带命名空间的模块。保证在变量名一样的时候，添加一个父级名拼接。
    state,
    getters,
    mutations,
    actions
  };
  