import Layout from "@/layout";

const gamesRouter = {
  path: "/game",
  component: Layout,
  redirect: "game/detail/:id",
  children: [
    {
      path: "/game/detail/:id",
      name: "gameDetail",
      component: () => import("@/views/game/detail"),
      meta: { title: "游戏详情" }
    }
  ]
};

export default gamesRouter;
