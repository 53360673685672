<template>
  <footer>
    CopyRight @ 2023 第九艺术商城 All rights reserved
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style lang="less" scoped>
  footer {
    background: #000;
    text-align: center;
    height: 60px;
    width: 100%;
    color: #fff;
    line-height: 60px;
  }
</style>
