<template>
  <div class="side">
    <div class="fixedBox">
      <div @click="isCar = true">
        <svg-icon size="34" :icon-class="token ? 'cart_warn' : 'cart_garay'" />
        <div class="cartCountCon" v-if="orderList.length > 0">
          <span class="cartCount">{{orderList.length}}</span>
        </div>
      </div>
      <div @click="kefu()">
        <svg-icon size="34" :icon-class="token ? 'kefu_warn' : 'kefu'" />
      </div>
    </div>
    <Drawer placement="right" width="340" :closable="false" v-model="isCar">
      <template slot="header">
        我的购物车：共 <span style="color: #ff7400; margin: 6px">{{orderList.length}}</span>款游戏
      </template>
      <template v-if="orderList.length > 0">
        <div class="main">
          <div class="order" v-for="(item, index) in orderList" :key="index">
            <div class="conten">
              <div class="top">
                <img :src="item.image" />
              </div>
              <div class="center">
                <div class="gameName"><span>{{item.game_cname}}</span><span></span></div>
                <div class="desc overflow">{{item.version_cname}} / {{item.district}} / {{item.good_type_name}}</div>
              </div>

              <div class="bottom">
                <div class="rate">{{item.off}}</div>
                <div>
                  <div class="price">￥{{item.price_final}}</div>
                  <div class="oldPrice">￥{{item.price_initial}}</div>
                </div>

              </div>
            </div>
            <div class="icon" @click="deleteOrder(item)">
              <svg-icon size="26" icon-class="delete" />
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="total">
            <span>合计：</span>
            <span class="money">￥<i>{{totalPrice}}</i></span>
          </div>
          <div class="payway">
            <!-- paypal -->
            <!-- <Button class="paypal" type="warning" @click="clickCar('paypal')">
              <svg-icon icon-class="paypal" class="paypal-icon"/>

            </Button> -->

            <!-- alipay & wechat -->
            <div class="pay-container">
              <Button class="alipay" type="success"  @click="clickCar('alipay')">
                <svg-icon size="25" icon-class="alipay" />
                <span>支付宝</span>
                <div class="alipayForm">
                  <form id="alipayForm" name="alipayForm" method="post" action="https://gateway.xddpay.com">
                    <input name='order_no' id='order_no' type='text' v-model="alipayFormPayData.order_no"/>
                    <input name='subject' id='subject' type='text' v-model="alipayFormPayData.subject" />
                    <input name='pay_type' id='pay_type' type='text' v-model="alipayFormPayData.pay_type" />
                    <input name='money' id='money' type='text' v-model="alipayFormPayData.money"/>
                    <input name='app_id' id='app_id' type='text' v-model="alipayFormPayData.app_id"/>
                    <input name='extra' id='extra' type='text' v-model="alipayFormPayData.extra"/>
                    <input name='sign' id='sign' type='text' v-model="alipayFormPayData.sign"/>
                    <input type='submit' id='submitAlipayForm'>
                  </form>
                </div>
              </Button>
              <Button class="weixin" type="success"  @click="clickCar('wechat')">
                <svg-icon size="25" icon-class="wechat_pay" />
                <span>微信支付</span>
              </Button>
            </div>
            <!-- <div id="paypal" ref="paypal"></div> --> <!-- 暂时关闭paypal支付-->
          </div>
        </div>
      </template>
      <div v-else class="cart_empty">
        <svg-icon size="150" icon-class="cart_empty" />
        <div class="gotobuy" @click="gotobuy">去选购</div>
      </div>
      <!-- 支付延迟转圈动画 -->
      <Spin fix v-if="isSpinShow">
          <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
          <div>正在支付下单</div>
      </Spin>
    </Drawer>
    <Modal
        v-model="kefumodal"
        title="第九艺术商城小助手"
        :footerHide = false
        width="320"
        style="text-align: center;">
        <img :src="kefuImgSrc" width="200px" height="200px"/>
    </Modal>
    
    
  </div>
</template>

<script>
import { pay, inviteSteamFriend, executePayment } from "@/api/cart.js";

export default {
  name: "SideCar",
  props: {
    token: {
      type: String,
      default: ""
    }
  },
  components: {

  },
  data() {
    return {
      isCar: false, // 侧边栏显示隐藏
      kefumodal: false, // 客服微信二维码弹窗
      kefuImgSrc:"",    //点击加载客服图片
      isSpinShow:0,   //支付下单延迟转圈提示
      orderList: this.$store.state.cart.gameList,
      // orderListLength:this.$store.state.cart.gameList.length, // 这样并不能监听到数组长度的变化
      alipayFormPayData: {},
      paypalData: {},
      steamid: "",
      client_id: "AZWyE9cU9wmIGmeoxd46JsQq5DuIM1WTqUHZ2iywEqmgeSJ7DfOMqVLd2FJOuHVnyC4TRK_thCNRk1XS"
    };
  },
  watch: {
    alipayFormPayData() {
      this.$nextTick(function() {
        console.log("UI渲染完成");
        document.getElementById("alipayForm").submit();
        // 提交订单后清空购物车并跳转至订单页
        this.$store.commit("cart/EMPTY_GAME");
        this.$router.replace({ path: "/order" });
      });
    },

    // 这样无法监听到旧数据
    // orderList(varNew){
    //   console.log(varNew)
    //   this.$nextTick(()=>{
    //     if(varNew.length == 1){
    //         this.setLoaded()
    //     }
    //   })
    // },

    // 这样才能监听到数组长度的变化
    "orderList.length": {
      handler(varNew, varOld) {
        // console.log(varNew)
        // console.log(varOld)
        this.$nextTick(() => {
          if (varNew == 1 && varOld == 0) {
            this.setLoaded();
          }
        });
      }
    }

  },
  mounted() {
    // 暂时关闭paypal支付
    // const oScript = document.createElement("script");
    // oScript.type = "text/javascript";
    // oScript.src = "https://www.paypal.com/sdk/js?client-id="+this.client_id+"&commit=false";
    // //oScript.src = "https://www.paypal.com/sdk/js?client-id=sb&commit=false";   //沙箱环境client-id直接固定设为sb
    // oScript.setAttribute("data-namespace", "paypal_sdk");
    // oScript.addEventListener("load", this.setLoaded);
    // document.body.appendChild(oScript);
  },
  computed: {
    totalPrice() {
      let _totalPrice = 0;
      if (this.orderList.length > 0) {
        for (const item of this.orderList) {
          _totalPrice += parseFloat(item.price_final);
        }
      }
      return parseFloat(_totalPrice).toFixed(2);
    }
  },
  methods: {
    setLoaded() {
      return; // 暂时关闭paypal支付
      const that = this;
      window.paypal_sdk.Buttons({
        env: "sandbox", /* sandbox | production */
        style: {
          layout: "horizontal", // 布局方式：vertical: 垂直，horizontal：水平，
          size: "responsive", /* medium | large | responsive*/
          shape: "pill", /* pill | rect*/
          color: "gold", /* gold | blue | silver | black*/
          label: "paypal",
          tagline: false // 是否显示按钮底部文字  true 显示  false 不显示
        },
        commit: false, // Show a 'Pay Now' button
        // createOrder() is called when the button is clicked
        createOrder: function() {
          const pay = that.clickCar("paypal");
          return pay.then(res => {
            return res.data.id;
          }, err => {
            if (err.code == 199) {
              // 弹出对话框提示用户填写steamid
              that.$Modal.confirm({
                title: err.msg,
                render: (h) => {
                  return h("Input", {
                    props: {
                      value: that.steamid,
                      autofocus: true,
                      placeholder: "请输入您常用的steamid"
                    },
                    on: {
                      input: (val) => {
                        that.steamid = val;
                      }
                    }
                  });
                },
                onOk: () => {
                  if (that.steamid == null || that.steamid == "" || that.steamid == undefined) {
                    that.$Message.warning("stemid不能为空");
                    return false;
                  }
                  inviteSteamFriend({ "steamid": that.steamid }).then(res => {
                    console.log(res);
                  });
                }
              });
            } else if (err.code == 198) {
              // 提示用户通过好友申请
              that.$Modal.confirm({
                "title": "提示",
                "content": '请登录steam帐号并通过好友申请，若未看到好友申请，可以点击下方的"再次加好友"',
                "okText": "再次加好友",
                "cancelText": "关闭"
              });
            } else {
              that.$Message.error(err.msg);
            }
          });
        },
        /* onApprove() is called when the buyer approves the payment */
        onApprove: function(data, actions) {
          return executePayment(data).then(res => {
            if (res.code == 200) {
              // 订单成功生成便清空购物车
              that.isCar = false;
              that.$store.commit("cart/EMPTY_GAME");
              that.$Message.success("支付成功");
              setTimeout(() => {
                that.$router.replace({ path: "/order" });
              }, 2000);
            } else {
              that.$Message.error(res.msg);
            }
          });
        },
        onCancel: function() {
          return window.location.href = "http://www.the9art.shop/home";
        },
        onError: function(err) {
          console.log(err);
        }
      }).render("#paypal");
      // 如果购物车不为空 则显示DOM
      if (this.orderList.length > 0) {
        this.$refs.paypal.style.display = "block";
      }
    },

    clickCar(paytype) {
      if (!this.token) {
        this.$Message.error("您还未登录，请先登录");
        this.isCar = false;
        if (this.$route.path.indexOf("login") !== -1) {
          return;
        }
        setTimeout(() => {
          this.$router.push({ path: "/login" });
        }, 1000);
      } else {
        // console.log(this.orderList)
        // 向服务端传递游戏gid 商品类型和总价
        const cartGameArr = [];
        for (const item of this.orderList) {
          const _cartGameObj = {
            "gid": item.gid,
            "good_type": item.good_type,
            "vid_id": item.vid,
            "district_id": item.district_id,
            "price_final": item.price_final,
            "price_initial": item.price_initial,
            "content_id": item.content_id
          };
          cartGameArr.push(_cartGameObj);
        }
        // 构造post数据
        const formData = {
          "cartGameArr": cartGameArr,
          "totalPrice": this.totalPrice,
          "payType": paytype
        };

        if (paytype == "paypal") {
          return pay(formData);
        }
        //下单前打开spin转圈提示
        this.isSpinShow = 1
        pay(formData).then(res => {
          //接口返回后关闭spin转圈提示
          this.isSpinShow = 0
          // 提交订单后清空购物车 如果使用表单提交订单则需要注释
          this.$store.commit("cart/EMPTY_GAME");
          if (paytype == "alipay" || paytype == "wechat") {
            //不再使用客户端支付下单的方式
            //this.alipayFormPayData = res.data;
            window.location.href = res.data

            // 直接提交form表单会出现数据为空的情况 因为vue的双向绑定有一定的时间延迟 在数据还没反映到表单上去的时候就立即进行提交就会出现数据为空的情况，使用settimeout是一种不优雅的方式
            // 最好的方法是在watch中使用 this.$nextTick 进行数据渲染完成的监听
            // setTimeout(() => {
            //   document.getElementById('alipayForm').submit();
            // }, 500);
          } else {
            //
          }
        }, err => {
          this.isSpinShow = 0
          // if (err.code == 199) {
          //   // 弹出对话框提示用户填写steamid
          //   this.$Modal.confirm({
          //     title: err.msg,
          //     render: (h) => {
          //       return h("Input", {
          //         props: {
          //           value: this.steamid,
          //           autofocus: true,
          //           placeholder: "请输入您常用的steamid"
          //         },
          //         on: {
          //           input: (val) => {
          //             this.steamid = val;
          //           }
          //         }
          //       });
          //     },
          //     onOk: () => {
          //       if (this.steamid == null || this.steamid == "" || this.steamid == undefined) {
          //         this.$Message.warning("stemid不能为空");
          //         return false;
          //       }
          //       inviteSteamFriend({ "steamid": this.steamid }).then(res => {
          //         console.log(res);
          //       });
          //     }
          //   });
          // } else if (err.code == 198) {
          //   // 提示用户通过好友申请
          //   this.$Modal.confirm({
          //     "title": "提示",
          //     "content": '请登录steam帐号并通过好友申请，若未看到好友申请，可以点击下方的"再次加好友"',
          //     "okText": "再次加好友",
          //     "cancelText": "关闭"
          //   });
          // }
          
          this.$store.commit("cart/EMPTY_GAME");
          this.$Message.error({
            content:err.msg,
            onClose:function(){
                window.location.reload()
            }
          })
        });
      }
    },
    deleteOrder(game_info) {
      // 删除购物车使用 findIndex先查找对象在对象数组中的索引值index 然后使用splice删除
      const _added_cart_games = this.$store.state.cart.gameList;
      const game_index = _added_cart_games.findIndex(game => game.gid == game_info.gid);
      if (game_index > -1) {
        this.$store.commit("cart/DEL_GAME", game_index);
      } else {
        console.log("删除错误");
      }
    },
    //购物车为空时去选购
    gotobuy() {
      this.isCar = false;
      if (this.$route.path != "/home") {
        this.$router.replace({ path: "/home" });
      }
    },
    //客服弹窗
    kefu(){
      this.kefumodal = true
      this.kefuImgSrc = "/img/Kefu.jpg"
    }

  }
};
</script>

<style lang="less" scoped>
  @import "index.less";
</style>
