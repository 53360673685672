<template>
  <div class="tabBar">
    <div class="logo">
      <img src="@/assets/img/logo.svg" />
    </div>
    <div
      class="item"
      :class="{active:itemActive === item.key}"
      v-for="(item, index) in tabBarData"
      @click="itemActive = item.key,clickTab(item.key)"
      :key="index"
    >
      {{ item.name }}
      <img v-if="item.key === 'Discount'" class="icon" src="@/assets/img/discount.png" alt="">
      <svg-icon class="svg" v-if="item.key === 'Hot'" size="19" icon-class="hot" />
      <div v-if="itemActive === item.key" class="line" />
    </div>
  </div>
</template>

<script>
export default {
  name: "TabBar",
  inject: ["tabBarData"],
  props: {
    activeItem: {
      type: String,
      default: "Home"
    }
  },
  data() {
    return {
      itemActive: "Home"
    };
  },
  methods: {
    clickTab(key) {
      if (this.$route.name === key) return;
      this.$router.replace({
        path: `/${key}`
        //path:'https://www.baidu.com' //不能跳转到外部网页
      });
    }
  }
};

</script>

<style lang="less" scoped>
  .tabBar {
    display: flex;
    height: 100%;
    .item {
      //width: 88px;
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 2px;
      color: #fff;
      position: relative;
      cursor: pointer;
      &:hover {
        background: #000;
      }
      .svg, .icon {
        position: absolute;
        top: 6px;
        right: 13px;
      }
      .line {
        position: absolute;
        top: 0;
        width: 100%;
        height: 3px;
        border-radius: 3px;
        background: @warn;
      }
    }
    .active {
      color: @warn;
    }
  }
</style>
