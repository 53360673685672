import request from "@/utils/request";

/**
 * 购物车支付结算
 */
export function pay(params) {
  return request({
    url: "/cart/pay",
    method: "post",
    params
  });
}

/**
 * PayPal确认支付
 */
export function executePayment(params) {
  return request({
    url: "/cart/paypalExecutePayment",
    method: "post",
    params
  });
}

/**
 * 发送好友申请
 */
export function inviteSteamFriend(data) {
  return request({
    url: "/cart/inviteSteamFriend",
    method: "post",
    data
  });
}
