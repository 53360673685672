import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/mixins";
import "@/assets/svg";
import "@/components/global";
import "@/assets/style/common.css";
import "@/assets/style/font.css";
import ViewUI from "view-design";
import "view-design/dist/styles/iview.css";
import "@/assets/style/base.less";
import "@/assets/style/index.less";
import * as filters from "./filters"; // 全局过滤器
import './utils/common.js'
Vue.use(ViewUI);
//Vue.use(VueAwesomeSwiper) //可注释

Vue.config.productionTip = false;

// 注册全局实用过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

//console.log(store)

new Vue({
  store,
  router,
  data: {
    eventHub: new Vue()
  },
  render: (h) => h(App)
}).$mount("#app");
