import { render, staticRenderFns } from "./tabBar.vue?vue&type=template&id=7db57f07&scoped=true&"
import script from "./tabBar.vue?vue&type=script&lang=js&"
export * from "./tabBar.vue?vue&type=script&lang=js&"
import style0 from "./tabBar.vue?vue&type=style&index=0&id=7db57f07&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7db57f07",
  null
  
)

export default component.exports