import request from "@/utils/request";

export function getdetail(params) {
  return request({
    url: "/game/detail",
    method: "post",
    params
  });
}

export function arrivalNotifyApi(params) {
  return request({
    url: "/Notify/arrivalNotify",
    method: "post",
    params
  });
}