import Layout from "@/layout";

const LoginRouter = {
  path: "/login",
  component: Layout,
  redirect: "/",
  children: [
    {
      path: "/",
      component: () => import("@/views/Login/index"),
      redirect: "/",
      children: [
        {
          path: "/",
          component: () => import("@/views/Login/login"),
          name: "login",
          meta: { title: "登录" }
        },
        {
          path: "/register",
          component: () => import("@/views/Login/register"),
          name: "register",
          meta: { title: "注册" }
        },
        {
          path: "/forgetPassword",
          component: () => import("@/views/Login/forgetPassword"),
          name: "forgetPassword",
          meta: { title: "忘记密码" }
        }
      ]
    }
  ]
};

export default LoginRouter;
