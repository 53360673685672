<template>
  <div class="swiper-main">
    <swiper :options="swiperOptionTop"  class="gallery-top" ref="swiperTop" v-if="swiperImg.length > 1">
      <swiper-slide v-for="itemImg of swiperImg" :key="itemImg.id">
        <div class="swiper-slide-container">
          <a :href="itemImg.url"><img :src="itemImg.image"  :alt="itemImg.title" /></a>
          <div class="swiper-slide-tips">
            <p class="tips-name left" style="color:#fff;">{{itemImg.description}}</p>
            <p class="tips-price right" style="color:#fff;"></p>
          </div>
        </div>
      </swiper-slide>

      <!-- <swiper-slide>
        <div class="swiper-slide-container">
          <a href="https://www.baidu.com"><img src="https://swiperjs.com/demos/images/nature-1.jpg"  /></a>
          <div class="swiper-slide-tips">
            <p class="tips-name left" style="color:#fff;"></p>
            <p class="tips-price right" style="color:#fff;"></p>
          </div>
        </div>
      </swiper-slide> -->

      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
//import "swiper/dist/css/swiper.css";
//import { swiper, swiperSlide } from "vue-awesome-swiper"; //https://github.surmon.me/vue-awesome-swiper
export default {
  name: "Swiper",
  props: ["swiperImg"],
  components: {
    swiper:VueAwesomeSwiper.swiper,
    swiperSlide:VueAwesomeSwiper.swiperSlide
  },
  data() {
    return {
      // swiperOptionTop: {
      //   spaceBetween: 10,
      //   effect: "fade",
      //   loop: true,
      //   loopedSlides: 5
      // },
      swiperOptionTop: {
        loop: true,
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        initialSlide: 0, // 初始化轮播图的位置
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        }
      },

      // swiperOptionThumbs: {
      //   spaceBetween: 18,
      //   loop: true,
      //   loopedSlides: 5,
      //   slidesPerView: 4, // 缩略图显示数量
      //   centeredSlides: true, // 选中的在中间
      //   slideToClickedSlide: true,
      //   watchSlidesVisibility: true
      // }
      
    };
  },
  methods: {
    swiperInit() {
      this.$nextTick(() => {
        const swiperTop = this.$refs.swiperTop.swiper;
        const swiperThumbs = this.$refs.swiperThumbs.swiper;
        swiperTop.controller.control = swiperThumbs;
        swiperThumbs.controller.control = swiperTop;
      });
    }
  }
  // updated() {
  //   this.$nextTick(() => {
  //     console.log(41);
  //     const swiperTop = this.$refs.swiperTop.swiper;
  //     const swiperThumbs = this.$refs.swiperThumbs.swiper;
  //     console.log(this.$refs);
  //     swiperTop.controller.control = swiperThumbs;
  //     swiperThumbs.controller.control = swiperTop;
  //   });
  // }
};
</script>

<style lang="less" scoped>
  // .example-3d {
  //   width: 100%;
  //   height: 400px;
  //   padding-top: 50px;
  //   padding-bottom: 50px;
  // }
  // .swiper {
  //   height: 100%;
  //   width: 100%;
  //   .swiper-slide {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     width: 300px;
  //     height: 300px;
  //     text-align: center;
  //     font-weight: bold;
  //     font-size:  20px;
  //     background-color: #2C8DFB;
  //     background-position: center;
  //     background-size: cover;
  //     color: white;
  //   }
  //   .swiper-pagination {
  //     /deep/ .swiper-pagination-bullet.swiper-pagination-bullet-active {
  //       background-color: white;
  //     }
  //   }
  // }

.swiper {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 600px;
  height: 350px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 350px;
}

.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.swiper-slide .title {
  font-size: 41px;
  font-weight: 300;
}

.swiper-slide .subtitle {
  font-size: 21px;
}

.swiper-slide .text {
  font-size: 14px;
  max-width: 400px;
  line-height: 1.3;
}

.swiper-slide-container{
  position: relative;
}

.swiper-slide-tips{
  position: absolute;
  width: 100%;
  height: 52px;
  background: rgba(0, 0, 0, .6);
  bottom: 0px;
  display: flex;
}
</style>
